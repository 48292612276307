:root {
  --yellow-primary: #ffa011;
  --blue-primary: #114684;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  height: 100vh;
  overflow: auto;
}

.tether-element.tether-element-attached-bottom.tether-element-attached-center.tether-target-attached-top.tether-target-attached-center.tether-enabled {
  top: 25% !important;
}

.pac-container {
  z-index: 9999 !important;
}

.css-1u2cvaz {
  align-items: center !important;
}

.readonly {
  background-color: #f5f5f5;
  pointer-events: none;
}

.insurence-select {
  border-radius: 5px !important;
  width: 100%;
  background: #114684 !important;
  text-align: center;
  color: white !important;
  min-width: 200px !important;

  option {
    color: black !important;
  }
}

.insurence-select + div {
  color: white !important;
}

.px-10 {
  padding: 0 10px !important;
}
.px-ADA-column {
  padding: 5px 10px !important;
}

.file-item {
  cursor: pointer;
}

/* .file-item-trash {
  display: none !important;
} */

/* .file-item:hover .file-item-trash {
  display: block !important;
} */

/* Legal Form editable table */
.pl-50 {
  padding-left: 30px !important;
}
.fw-bold {
  font-weight: bold !important;
}
.fw-normal {
  font-weight: normal !important;
}

.liens-details {
  padding: 0 !important;
}

/* .liens-table-parent-row {
  background-color: rgba(17, 70, 132, 0.2) !important;
  box-shadow: none !important;
} */

/* .legal-form-table tr > .liens-table-parent-row {
  border: 1px solid rgba(17, 70, 132, 0.2) !important;
} */

.legal-form-table tr > th {
  box-shadow: none !important;
  border: 1px solid lightgray !important;
}

.legal-form-table tr > td {
  box-shadow: none !important;
  border: 1px solid lightgray !important;
}

.editable-table-parentRow tr {
  background-color: rgba(17, 70, 132, 0.2) !important;
}

.liens-table-parent-row {
  background-color: transparent !important;
}

.broker_dashboard_table .mantine-DateInput-input,
.broker_dashboard_table .mantine-TextInput-input {
  color: white !important;
}

/*date filter dashboard calender css*/
.mantine-Calendar-calendar table {
  border: unset !important;
}

.mantine-Calendar-calendar table thead tr th,
.mantine-Calendar-calendar table thead tr .th,
.mantine-Calendar-calendar table tbody tr td,
.mantine-Calendar-calendar table tbody tr .td {
  box-shadow: unset !important;
  padding: 0.25rem !important;
  width: 2.25rem;
  height: 2.25rem;
}

.mantine-Popover-dropdown {
  z-index: 45 !important;
}

.datePicker_fiscalendDate .mantine-Popover-dropdown {
  border: 1px solid lightgray !important;
}

.custom-date-picker {
  min-width: unset !important;
}

.financial_statement_dropdown option:disabled {
  color: grey !important;
  /* background-color: lightgrey !important; */
  cursor: not-allowed !important;
}

/* Uploads tab report datePicker*/
.upload_report_date .react-datepicker-input {
  height: 35px !important;
}
.upload_report_date .react-datepicker-input input {
  border-radius: 5px;
  font-weight: normal !important;
  font-size: 14px !important;
}

.upload_report_date .react-datepicker-input input::placeholder {
  color: #9091a0 !important;
  font-weight: normal !important;
}
.upload_report_date .icon-rc-datepicker_calendar {
  color: #9091a0 !important;
}

.custom-swal {
  z-index: 2000 !important;
}

.bondRequest_reject .mantine-Modal-content {
  overflow-y: unset !important;
}

.custom-mantine-hover-card {
  z-index: 500 !important;
}
.support-ticket-modal-content::-webkit-scrollbar {
  width: 6px !important;
}

/* Transparent scrollbar track */
.support-ticket-modal-content::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Transparent scrollbar thumb */
.support-ticket-modal-content::-webkit-scrollbar-thumb {
  background: transparent !important; /* Light transparent thumb */
  border-radius: 6px !important;
}
